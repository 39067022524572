<template>
  <div>
      <LiefengContent>
        <template v-slot:title>预警信息</template>
        <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <div class="container-btn">
                     <div :class="changeIndex == index ?'item item-active':'item'" v-for="(item,index) in menuList" :key="index" @click="changeMenu(index)">{{item.name}}</div>
                 </div>
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        </template>
      </LiefengContent>
      <LiefengModal
        title="预警信息详情"
        :fullscreen="false"
        :value="detailData.status"
        @input="detailDataFn"
        class="analysis"
      >
        <template #contentarea>
            <div class="detail-container">
                <div class="title"><div class="left">预警信息:</div><div class="right">{{'开门持续24小时未关'}}</div></div>
                <div class="title"><div class="left">地址:</div><div class="right">{{'番禺区小谷围街道星汇社区键盘花园16栋506房'}}</div></div>
            </div>
            <div class="equiment-title">设备信息</div>
            <div class="datail-equiment">
                <Table stripe border  :columns="detailTalbeColumns" :data="detailTableData"></Table>
            </div>
            <div class="equiment-title">操作记录</div>
            <div class="datail-equiment">
                <Table stripe border  :columns="detailTwoTalbeColumns" :data="detailTwoTableData"></Table>
            </div>
        </template>
        <template #toolsbar>
            <Button type="primary" @click="detailDataFn(false)">关闭</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
  //@route("/jujiaearly")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengTable,LiefengModal},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                title: "名称",
                key: "name",
                minWidth: 200,
                align: "center"
            },
            {
                title: "性别",
                key: "sex",
                minWidth: 200,
                align: "center"
            },
            {
                title: "年龄",
                key: "age",
                minWidth: 200,
                align: "center"
            },
            {
                title: "地址",
                key: "orgCode",
                minWidth: 200,
                align: "center"
            },
            {
                title:'操作',
                align:'center',
                minWidth:100,
                render:(h,params) =>{
                     return h(
                        "div",
                        {
                            style: {
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },
                        [
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                            title:'温馨提示',
                                            content:'确定有人去处理这个预警信息吗？',
                                            onOk: () => {

                                            }
                                        })
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "我去处理" //1001
                            ),       
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.detailData.status = true
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "信息详情" //1001
                            ),       
                         ]
                     );
                }
            }
          ],
          tableData:[
                {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
                {name:'牛',sex:'男',age:81,orgCode:'番禺区小谷围街道星汇社区键盘花园WN栋7117房'},
          ],
          loading:false,
          page:0,
          total:0,
          pageSize:20,
          menuList:[
              {name:'全部',value:0},
              {name:'未处理',value:1},
              {name:'处理中',value:2},
              {name:'已处理',value:3},
          ],
          changeIndex:0,
          detailData:{
              status:false
          },
          detailTalbeColumns:[
              {
                title: "预警设备",
                key: "name",
                align: "center"
            },
            {
                title: "房间",
                key: "room",
                align: "center"
            },
          ],
          detailTableData:[
              {name:'门磁',room:'客厅'},
              {name:'门磁',room:'客厅'},
          ],
          detailTwoTalbeColumns:[
              {
                title: "阶段状态",
                key: "status",
                align: "center",
                render:(h,params) =>{
                    return h('div',{
                        style:{
                            color:params.row.status == '处理中' ? '#2db7f5' : params.row.status == '未处理' ? 'red' :''
                        }
                    },params.row.status)
                }
             },
             {
                 title: "操作人",
                 key: "people",
                 align: "center"
             },
             {
                 title: "发生事件",
                 key: "time",
                 align: "center"
             },
          ],
          detailTwoTableData:[
              {status:'已处理',people:'张三',time:'2021-10-23 15:23'},
              {status:'处理中',people:'张三',time:'2021-10-23 15:23'},
              {status:'未处理',people:'张三',time:'2021-10-23 15:23'},
          ]
      }
    },
    //方法集合
    methods: {
        hadlePageSize(){},
        changeMenu(index){
            this.changeIndex = index
        },
        // 模态框关闭事件
        detailDataFn(status){
            this.detailData.status = status
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
  }
</script>
<style lang='less' scoped>
  //编写css
  .container{
      .container-btn{
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          .item{
              width: 80px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border: 1px solid rgba(000, 000, 000, 0.1);
              margin-right: 10px;
              cursor: pointer;
              user-select: none;
              border-radius: 5px;
               &-active{
                    background: #2d8cf0;
                    color: #ffffff;
                }
        }
      }
     
  }
   .detail-container{
          .title{
              display: flex;
              justify-content: flex-start;
              width: 100%;
              margin: 10px 0;
              font-size: 16px;
              .left{
                  width: 15%;
                  
              }
              .right{
                  width: 85%;
              }
          }
    }
    .equiment-title{
        position: relative;
        padding-left: 20px;
        margin:10px 0;
        &::after{
            position: absolute;
            content:'';
            top: 0;
            left: 0;
            width: 5px;
            height: 20px;
            background-color: #2db7f5;
        }
    }
</style>